import React from "react";
import JoditEditor from "jodit-react";

import "./htmlEditor.css";
interface Props {
  onBlur: (e: any) => void;
  onChange: (e: any) => void;
  value: string;
}

const HtmlEditor: React.FC<Props> = ({ onBlur, onChange, value = "" }) => (
  <JoditEditor onBlur={onBlur} onChange={onChange} value={value} />
);

export { HtmlEditor };
