import { apiClient } from "../helpers";

const baseUrl = `${apiClient.hostUrl}/CardDetail`;

export const cardDetailService = {
    getCardDetailsByTenant
};

function getCardDetailsByTenant(tenantId:any) {
    return apiClient.get(`${baseUrl}/GetCardDetailsByTenant/${tenantId}`);
}

