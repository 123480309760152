import { Drawer, Table, Space, Button, Tag, Popconfirm } from "antd";
import type { FixedType } from "rc-table/es/interface";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { useState, useEffect } from "react";
import { loginRequest } from "../../config/authConfig";
import { Loading, ErrorComponent } from "../../components";
// import { getFormattedDate } from "../../helpers";
import { Help, helpService, Category, categoryService } from "../../services";
import { HelpForm } from "./helpForm";

const HelpListContent = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [categories, setCategories] = useState<Category[]>([]);
  const [helps, setHelps] = useState<Help[]>([]);
  const [editingHelp, setEditingHelp] = useState<Help>();

  useEffect(() => {
    categoryService
      .getAll()
      .then((res) => {
        if (!res.isError) {
          setCategories(res.result);
        }
        return helpService.getAll();
      })
      .then((res) => {
        if (!res.isError) {
          setHelps(res.result);
        }
      });
  }, []);

  const onSave = (values: Help) => {
    const newHelps = [...helps];
    const index = newHelps.findIndex((help) => help.id === values.id);
    if (index >= 0) {
      newHelps[index] = values;
    } else {
      newHelps.push(values);
    }
    setHelps(newHelps);
    onCloseDrawer();
  };

  const onCloseDrawer = () => {
    setEditingHelp(undefined);
    setDrawerVisible(false);
  };

  const handleEdit = async (help: Help) => {
    setEditingHelp(help);
    setDrawerVisible(true);
  };

  const handleDelete = async (helpId: string) => {
    await helpService.delete(helpId);
    const newHelps = helps.filter((help: Help) => help.id !== helpId);
    setHelps(newHelps);
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      fixed: "left" as FixedType,
    },
    { title: "Description", dataIndex: "description", key: "description" },
    {
      title: "Category",
      dataIndex: "categoryId",
      key: "categoryId",
      render: (categoryId: string) =>
        categories.find((category) => category.id === categoryId)?.name,
    },
    {
      title: "Sub Category",
      dataIndex: "subCategoryId",
      key: "subCategoryId",
      render: (subCategoryId: string) =>
        categories.find((category) => category.id === subCategoryId)?.name,
    },
    { title: "Content", dataIndex: "content", key: "content", ellipsis: true },
    {
      title: "Keywords",
      dataIndex: "keywords",
      key: "keywords",
      render: (keywords: string[]) => (keywords || []).join(", "),
    },
    {
      title: "External Video URL",
      dataIndex: "externalVideoURL",
      key: "externalVideoURL",
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (isActive: boolean) => {
        const color = isActive ? "green" : "volcano";
        return <Tag color={color}>{isActive ? "Active" : "Inactive"}</Tag>;
      },
    },
    // {
    //   title: "Created Date",
    //   dataIndex: "createdOn",
    //   key: "createdOn",
    //   render: (date: string) => date ? getFormattedDate(date) : '',
    // },
    {
      title: "Action",
      key: "operation",
      fixed: "right" as FixedType,
      render: (_: any, record: Help) => {
        return helps.length ? (
          <Space size="middle">
            <a onClick={() => handleEdit(record)}>Edit</a>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record.id)}
            >
              <a>Delete</a>
            </Popconfirm>
          </Space>
        ) : null;
      },
    },
  ];

  return (
    <div>
      <Button
        onClick={() => setDrawerVisible(true)}
        type="primary"
        style={{ marginBottom: 16 }}
      >
        New Help
      </Button>
      <Table
        className="components-table-demo-nested"
        columns={columns}
        dataSource={helps.map((help, index) => ({
          ...help,
          key: help.id || index,
        }))}
        scroll={{ x: 1100 }}
      />

      <Drawer
        title={editingHelp ? "Edit the help" : "Create a new help"}
        width={1240}
        onClose={onCloseDrawer}
        visible={drawerVisible}
        extra={
          <Space>
            <Button onClick={onCloseDrawer}>Cancel</Button>
            <Button form="help-form" type="primary" htmlType="submit">
              Submit
            </Button>
          </Space>
        }
      >
        <HelpForm
          key={Number(drawerVisible)}
          help={editingHelp}
          onSave={onSave}
        />
      </Drawer>
    </div>
  );
};

const HelpList = ({ history, match }: any) => {
  const authRequest = {
    ...loginRequest,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Popup}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <HelpListContent />
    </MsalAuthenticationTemplate>
  );
};

export { HelpList };
