import React, { useState } from 'react'
import Cards from 'react-credit-cards';
import { Form, Input, Checkbox, Card, Row, Col } from 'antd';
import MaskedInput from 'antd-mask-input'
import 'react-credit-cards/es/styles-compiled.css'
import SavedCreditCardList from './saveCreditCards';
import Layout from 'antd/lib/layout/layout';



const CreditCardForm = React.forwardRef(({ tenant, onSubmit, onSubmitFailed, form }:any, ref) => {

    const [savedCardValue, setSavedCardValue] = useState(1);
    const [cardnumber, setNumber] = useState('')
    const [name, setName] = useState('')
    const [expiry, setExpiry] = useState('')
    const [cvc, setCvc] = useState('')
    const [focus, setFocus] = useState('')
    

    const onSavedCardChanged = (e:any,cardDetails:any) => {
        let itemIndex = cardDetails.findIndex((item:any) => item.id === e.target.value);
        let card = cardDetails[itemIndex];
        if(card){
        form.setFieldsValue({
            CreditCardHolderName: card.cardHolder,
            CreditCardNumber: card.cardNumber,
            CreditCardExpirationDate: card.expiryDate.toString(),
            CreditCardCode: card.cardCode.toString(),
          });
          setNumber(card.cardNumber);
          setExpiry(card.expiryDate);
        }
        setSavedCardValue(e.target.value);
    };
    
    return (
        <div style={{ margin: '30px' }}>
            <Card>
                <Row>
                    <Col span={12}>
                       <SavedCreditCardList tenant={tenant}  onSavedCardChanged={onSavedCardChanged} value={savedCardValue}></SavedCreditCardList>
                    </Col>
                    <Col span={12}>
                        <Cards
                            number={cardnumber}
                            name={name}
                            expiry={expiry}
                            cvc={cvc}                            
                            // focused={focus}
                        />
                        <Form name="control-hooks"
                            layout='vertical'
                            // name="credi-CardForm"
                            form={form}
                            // ref={ref}
                            onFinish={onSubmit}
                            onFinishFailed={onSubmitFailed}
                        >
                            <Form.Item
                                label="Name"
                                name="CreditCardHolderName"
                                rules={[{ required: true, }]}
                            >
                                <Input name="CreditCardHolderName" placeholder='Enter Name'  onFocus={e => setFocus(e.target.name)} onChange={e => setName(e.target.value)} />
                            </Form.Item>
                            <Form.Item
                                label="Card Number"
                                name="CreditCardNumber"
                                rules={[{ required: true, }]}
                            >
                                <MaskedInput mask="1111 1111 1111 1111" type='tel' name="CreditCardNumber" placeholder="Card Number" onChange={e => setNumber(e.target.value)}
                                    onFocus={e => setFocus(e.target.name)} />
                            </Form.Item>

                            <Form.Item
                                label="Expiry Date"
                                name="CreditCardExpirationDate"
                                rules={[{ required: true, }]}
                            >
                                <MaskedInput mask="1111" type='tel' name="CreditCardExpirationDate" placeholder="MMYY" onFocus={e => setFocus(e.target.name)} onChange={e => setExpiry(e.target.value)} />
                            </Form.Item>

                            <Form.Item
                                label="CVC"
                                name="CreditCardCode"
                                rules={[{ required: true, }]}
                            >
                                <MaskedInput type='tel' mask="111" name="CreditCardCode" onFocus={e => setFocus(e.target.name)} onChange={e => setCvc(e.target.value)} />
                            </Form.Item>
                            <Form.Item name="IsRememberMe" valuePropName="checked" noStyle>
                                <Checkbox>Remember me</Checkbox>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Card>

        </div>

    );

});

export default CreditCardForm;