import { Tag, Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React, { useState, useRef, useEffect } from "react";

interface Props {
  onChange: (e: any) => void;
  value: string[];
}

const EditableTagGroup: React.FC<Props> = ({ value, onChange }) => {
  const [tags, setTags] = useState<string[]>([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  // @ts-expect-error this is ok
  const inputRef = useRef<Input>(null);

  useEffect(() => {
    setTags(value || [])
  }, [value])

  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
    onChange(newTags)
  };

  const showInput = () => {
    setInputVisible(true);
    inputRef.current?.focus();
  };

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    let newTags: string[] = [];

    if (inputValue && tags.indexOf(inputValue) === -1) {
      newTags = [...tags, inputValue];
    }

    setTags(newTags);
    onChange(newTags);
    setInputVisible(false);
    setInputValue("");
  };

  const tagChild = tags.map((tag: string, index: number) => {
    const tagElem = (
      <Tag
        key={index}
        color="#1890ff"
        closable
        onClose={(e) => {
          e.preventDefault();
          handleClose(tag);
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span key={tag} style={{ display: "inline-block" }}>
        {tagElem}
      </span>
    );
  });

  return (
    <>
      <div style={{ marginBottom: 16 }}>{tagChild}</div>
      {inputVisible && (
        <Input
          ref={inputRef}
          type="text"
          size="small"
          style={{ width: 78 }}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      )}
      {!inputVisible && (
        <Tag
          onClick={showInput}
          style={{
            background: "#fff",
            borderStyle: "dashed",
          }}
        >
          <PlusOutlined /> New Tag
        </Tag>
      )}
    </>
  );
};

export { EditableTagGroup };
