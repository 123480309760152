import { apiClient } from "../helpers";

const baseUrl = `${apiClient.hostUrl}/Category`;

export interface Category {
  id: string;
  name: string;
  description: string;
  parentId: string;
  isActive: boolean;
}

export const categoryService = {
  getAll,
  GetSubCategoryByParent,
  create,
  update,
  delete: _delete
};

function getAll() {
  return apiClient.get(baseUrl);
}

function GetSubCategoryByParent(parentId: any) {
  return apiClient.get(`${baseUrl}/GetSubCategoryByParent/${parentId}`);
}

function create(params: any) {
  return apiClient.post(baseUrl, params);
}

function update(id:any, params:any) {
  return apiClient.put(`${baseUrl}/${id}`, params);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(id:any) {
  return apiClient.delete(`${baseUrl}/${id}`);
}