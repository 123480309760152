import { Configuration, PopupRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: "f6e49ac2-c1d1-4561-9930-2f9e914ef0ae", // This is the ONLY mandatory field that you need to supply.
        authority: "https://login.microsoftonline.com/629c77eb-a481-429f-8511-a456b814c713",
        redirectUri: "/",
        postLogoutRedirectUri: "/"
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: ["User.Read"]
};