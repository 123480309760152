import React, { useState } from "react";
import { Table, Space, Button, Drawer, Tag } from "antd";
import { useEffect } from "react";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import {
  InteractionType,
} from "@azure/msal-browser";
import { loginRequest } from "../../config/authConfig";
import { ErrorComponent } from "../../components/error/errorComponent";
import { Loading } from "../../components";
import AdminRoleTemplate from "../../components/admin/adminRoleTemplate";
import { getFormattedDate } from "../../helpers";
import { Tenant, tenantService } from "../../services";
import { TenantForm } from "./tenantForm";

function TenantListContent({ history, match }: any) {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingTenant, setEditingTenant] = useState<Tenant>()
  const [tenants, setTenants] = useState<Tenant[]>([]);

  useEffect(() => {
    tenantService.getAll().then((res) => {
      if (!res.isError) {
        setTenants(res.result)
      }
    })
  }, []);

  const onSave = (values: Tenant) => {
    const newTenants = [...tenants];
    const index = newTenants.findIndex((tenant) => tenant.id === values.id);
    if (index >= 0) {
      newTenants[index] = values;
    } else {
      values.createdOn = new Date().toISOString();
      newTenants.push(values);
    }
    setTenants(newTenants);
    onCloseDrawer();
  };

  const onCloseDrawer = () => {
    setEditingTenant(undefined);
    setDrawerVisible(false);
  };

  const handleEdit = async (tenant: any) => {
    setEditingTenant(tenant);
    setDrawerVisible(true);
  };

  const columns = [
    { title: "First Name", dataIndex: "firstName", key: "firstName" },
    { title: "Last Name", dataIndex: "lastName", key: "lastName" },
    { title: "Email Address", dataIndex: "emailAddress", key: "emailAddress" },
    { title: "Country", dataIndex: "country", key: "country" },
    { title: "Company", dataIndex: "company", key: "company" },
    { title: "Company URL", dataIndex: "companyURL", key: "companyURL" },
    { title: "Phone Number", dataIndex: "phoneNumber", key: "phoneNumber" },
    {
      title: "Created Date",
      dataIndex: "createdOn",
      key: "createdOn",
      render: (date: string) => date ? getFormattedDate(date) : '',
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (isActive: boolean) => {
        const color = isActive ? "green" : "volcano";
        return <Tag color={color}>{isActive ? "Active" : "Inactive"}</Tag>;
      },
    },
    {
      title: "Action",
      key: "operation",
      render: (_: any, record: any) => (
        <Space size="middle">
          <a onClick={() => handleEdit(record)}>Edit</a>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Button
        onClick={() => setDrawerVisible(true)}
        type="primary"
        style={{ marginBottom: 16 }}
      >
        New Tenant
      </Button>
      <Table
        className="tenants-table"
        columns={columns}
        dataSource={tenants}
      />
      <Drawer
        title={editingTenant ? "Edit the tenant" : "Create a new tenant"}
        width={540}
        onClose={onCloseDrawer}
        visible={drawerVisible}
        extra={
          <Space>
            <Button onClick={onCloseDrawer}>Cancel</Button>
            <Button form="tenant-form" type="primary" htmlType="submit">
              Submit
            </Button>
          </Space>
        } >
        <TenantForm
          key={Number(drawerVisible)}
          tenant={editingTenant}
          onSave={onSave}
        />
      </Drawer>
    </div>
  );
}

function TenantList({ history, match }: any) {
  const authRequest = {
    ...loginRequest,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Popup}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <AdminRoleTemplate>
        <TenantListContent />
      </AdminRoleTemplate>
    </MsalAuthenticationTemplate>
  );
}

export { TenantList };
