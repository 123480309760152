import { apiClient } from "../helpers";

const baseUrl = `${apiClient.hostUrl}/Admin`;
export const userService = {
    getCountries,
    getAll,
    getById,
    create,
    update,
    delete: _delete,
    isCompanyExist
};

function getAll() {
    return apiClient.get(baseUrl);
}
function getCountries()
{
    return apiClient.get("https://cdn.jsdelivr.net/npm/country-list@2.2.0/data.json");
}
function getById(id:any) {
    return apiClient.get(`${baseUrl}/${id}`);
}

function isCompanyExist(company:any) {
    return apiClient.get(`${baseUrl}/IsTenantExist/${company}`);
}
function create(params:any) {
    return apiClient.post(baseUrl, params);
}

function update(id:any, params:any) {
    return apiClient.put(`${baseUrl}/${id}`, params);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(id:any) {
    return apiClient.delete(`${baseUrl}/${id}`);
}
