import React, { useState } from "react";
import { Table, Space, Button } from "antd";
import { useEffect } from "react";
import { subscriptionService } from "../../services";
import PaymentModel from "../../components/payment/payment-model";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import {
  InteractionStatus,
  InteractionType,
  InteractionRequiredAuthError,
  AccountInfo,
} from "@azure/msal-browser";
import { loginRequest } from "../../config/authConfig";
import { ErrorComponent } from "../../components/error/errorComponent";
import { Loading } from "../../components";
import AdminRoleTemplate from "../../components/admin/adminRoleTemplate";
import { getFormattedDate } from "../../helpers";

function SubscriptionListContent({ history, match }: any) {
  const [isVisible, setVisibleModel] = useState(false);
  const [selectedTenant, setSelectedTenant] = useState({});
  const [subscriptions, setSubscriptions] = useState([]);
  useEffect(() => {
    subscriptionService.getSubscriptions().then((res) => {
      if (!res.isError) {
        setSubscriptions(res.result);
      }
    });
  }, []);

  const showModal = () => {
    let isFlag: boolean = true;
    setVisibleModel(isFlag);
  };
  const paymentClick = (record: any) => {
    let rowIndex = subscriptions.findIndex(
      (item: any) => item.tenantId == record.key
    );
    let groupedTenant = subscriptions[rowIndex];
    setSelectedTenant(groupedTenant);
    showModal();
  };

  const expandedRowRender = (record: any) => {
    const columns = [
      {
        title: "Subscription",
        children: [
          {
            title: "Start Date",
            dataIndex: "startdate",
            key: "startdate",
          },
          {
            title: "End Date",
            dataIndex: "enddate",
            key: "enddate",
          },
        ],
      },
      { title: "Role", dataIndex: "role", key: "role" },
      { title: "Users", dataIndex: "userCount", key: "userCount" },
      { title: "RolePrice/User", dataIndex: "rolePrice", key: "rolePrice" },
      { title: "Amount", dataIndex: "amount", key: "amount" },
    ];

    const data = [];
    const subscription: any = subscriptions.find(
      (i: any) => i.tenantId == record.key
    );
    if (
      subscription &&
      subscription.roleWiseUsers &&
      subscription.roleWiseUsers.length
    ) {
      for (let i = 0; i < subscription.roleWiseUsers.length; ++i) {
        const item = subscription.roleWiseUsers[i];
        data.push({
          key: i,
          role: item.role,
          userCount: item.userCount,
          rolePrice: item.rolePrice,
          amount: item.amount,
          startdate: getFormattedDate(item.subscriptionStartDate),
          enddate: getFormattedDate(item.subscriptionEndDate),
        });
      }
    }
    return <Table columns={columns} dataSource={data} pagination={false} />;
  };

  const columns = [
    { title: "Tenant", dataIndex: "tenant", key: "tenant" },
    { title: "Billable Amount", dataIndex: "totalAmount", key: "totalAmount" },
    {
      title: "Action",
      dataIndex: "pay",
      key: "pay",
      render: (_: any, record: any) => (
        <Space size="middle">
          <Button type="primary" onClick={() => paymentClick(record)}>
            Pay
          </Button>
        </Space>
      ),
    },
  ];

  const data = [];
  if (subscriptions.length) {
    for (let i = 0; i < subscriptions.length; ++i) {
      const item: any = subscriptions[i];
      data.push({
        key: item.tenantId,
        tenant: item.tenant,
        totalAmount: item.totalAmount,
      });
    }
  }

  return (
    <div>
      <Table
        className="components-table-demo-nested"
        columns={columns}
        expandable={{ expandedRowRender }}
        dataSource={data}
      />
      <PaymentModel
        visible={isVisible}
        setIsModalVisible={setVisibleModel}
        tenant={selectedTenant}
      ></PaymentModel>
    </div>
  );
}

function SubscriptionList({ history, match }: any) {
  const authRequest = {
    ...loginRequest,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Popup}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <AdminRoleTemplate>
        <SubscriptionListContent />
      </AdminRoleTemplate>
    </MsalAuthenticationTemplate>
  );
}

export { SubscriptionList };
