import React, { useState } from 'react';
import 'antd/dist/antd.css';
import './orderSummary.css';
import { Row, Col, Typography, Divider, Card } from 'antd';
const { Title } = Typography;
const RenderRow = ({ row }:any) => {
    return (
        <Row justify="center">
            <Col span={4} >{row.role}</Col>
            <Col span={4} >{row.userCount}</Col>
            <Col span={4} >${row.rolePrice}</Col>
            <Col span={4} >${row.amount}</Col>
        </Row>

    );

}
export default function OrderSummary({ tenant, ...props }:any) {
    const { roleWiseUsers } = tenant;
    return (
        <Card className="orderSummaryCard">
            <div >
            <Row justify="center">
            <Col span={4} ><Title level={5}>Role</Title></Col>
            <Col span={4} ><Title level={5}># Users</Title></Col>
            <Col span={4} ><Title level={5}>Role Price</Title></Col>
            <Col span={4} ><Title level={5}>Amount</Title></Col>
        </Row>
                <Divider plain></Divider>
                {roleWiseUsers && roleWiseUsers.length > 0 ? roleWiseUsers.map((item:any,index:any) => <RenderRow key={index} row={item}></RenderRow>) : null}
                <Divider plain></Divider>
                <Row justify="center">
                    <Col span={4} ></Col>
                    <Col span={4} ></Col>
                    <Col span={4} ><Title level={5}>Total Amount </Title></Col>
                    <Col span={4} ><Title level={5}>${tenant.totalAmount}</Title></Col>
                </Row>
            </div>
        </Card>

    );
}