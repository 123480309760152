import React from "react";
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  NavLink,useHistory
} from "react-router-dom";

import DisplayAlert from "./components/alert/alert";
import { SignUp } from "./pages/user/signup";
import { Layout, Menu } from "antd";
import { ApartmentOutlined, UserOutlined } from "@ant-design/icons";
// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { CustomNavigationClient } from "./utils/CustomNavigationClient";
import { Home } from "./pages/home/home";

const { Content, Sider } = Layout;
type AppProps = {
  pca: IPublicClientApplication;
};
function App({ pca }: AppProps) {
  const { pathname } = useLocation();
// The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
const history = useHistory();
const navigationClient = new CustomNavigationClient(history);
pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>
      <Home></Home>
    </MsalProvider>
  );
}

export default App;
