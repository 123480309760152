import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import './orderSummary.css';
import { Row, Col, Typography, Card, Space, Radio, Image } from 'antd';
import {  CreditCard } from '../../assets/svgs'
const { Title } = Typography;

const methods = [
    {
        title: "Credit-Card",
        value: 1,
        svg: CreditCard,
        type: 'CreditCard'
    }
    // {
    //     title: "Bank",
    //     value: 2,
    //     svg: BankSvg,
    //     type: 'ACH'
    // }
    
]
const RenderRadio = ({ row }:any) => {
    return (
        <Card > <Radio value={row.value}><Row gutter={16} align='middle'>
            <Col style={{ alignItems: 'center' }} span={16}>
                <Title level={5}> {row.title}</Title>
            </Col>
            <Col span={8}>
                <Image preview={false} height={35} width={35} src={row.svg}></Image>
            </Col>
        </Row></Radio></Card>

    );

}
export default function PaymentMethod({ onPaymentMethodChange, ...props }:any) {
    const [value, setValue] = useState(0);

    const onMethodChange = (e:any) => {
        setValue(e.target.value);
    };
    useEffect(() => {
        let itemIndex = methods.findIndex(item => item.value == value);
        onPaymentMethodChange(methods[itemIndex]);


    }, [value]);
    return (
        <Card className="orderSummaryCard">
            <div >
                <Row justify="center">
                    <Radio.Group onChange={onMethodChange} value={value}>
                        <Space direction='vertical' >
                            {methods.map((item, i) => <RenderRadio key={i} row={item}></RenderRadio>)}
                        </Space>
                    </Radio.Group>
                </Row>
            </div>
        </Card>

    );
}