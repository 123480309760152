import React, { useState, useEffect } from "react";
import { Form, Input, Switch, Select } from "antd";
import { tenantService } from "../../services";
import { getCountries } from "../../helpers";

interface Params {
  tenant?: any;
  onSave: (values: any) => void;
}

const TenantForm = ({ tenant, onSave }: Params) => {
  const [countries, setCountries] = useState<any>([]);
  const isEditing = !!tenant;

  useEffect(() => {
    let _countries = getCountries.map((item) => {
      return { label: item.name, value: item.name };
    });
    setCountries(_countries);
    // eslint-disable-next-line
  }, []);

  const onFinish = async (values: any) => {
    if (isEditing) {
      values = {
        ...tenant,
        ...values,
      };
      await tenantService.update(values);
    } else {
      await tenantService.create(values);
    }

    onSave(values);
  };

  return (
    <Form
      id="tenant-form"
      layout="vertical"
      initialValues={tenant}
      onFinish={onFinish}
    >
      <Form.Item
        label="First Name"
        name="firstName"
        rules={[{ required: true, message: "First Name is required!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Last Name"
        name="lastName"
        rules={[{ required: true, message: "Last Name is required!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Email Address"
        name="emailAddress"
        rules={[{ required: true, message: "Email Address is required!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Country"
        name="country"
        rules={[{ required: true, message: "Country is required!" }]}
      >
        <Select
          showSearch
          placeholder="Select a country"
          optionFilterProp="children"
          options={countries}
          filterOption={(input: any, option: any) =>
            option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        ></Select>
      </Form.Item>
      <Form.Item
        label="Company"
        name="company"
        rules={[{ required: true, message: "Company is required!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Company URL"
        name="companyURL"
        rules={[{ required: true, message: "Company URL is required!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Phone Number"
        name="phoneNumber"
        rules={[{ required: true, message: "Phone Number is required!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Active" name="isActive" valuePropName="checked">
        <Switch />
      </Form.Item>
    </Form>
  );
};

export { TenantForm };
