import { apiClient } from "../helpers";

const baseUrl = `${apiClient.hostUrl}/Help`;

export interface Help {
  id: string;
  title: string;
  description: string;
  categoryId: string;
  subCategoryId: string;
  externalVideoURL: string;
  isActive: boolean;
  createdBy: string;
  updatedBy: string;
  createdDate: string;
  updatedDate: string;
}

export const helpService = {
  getAll,
  getById,
  create,
  update,
  delete: _delete
};

function getAll() {
  return apiClient.get(baseUrl);
}

function getById(id: any) {
  return apiClient.get(`${baseUrl}/GetById/${id}`);
}

function create(params: any) {
  return apiClient.post(baseUrl, params);
}

function update(params:any) {
  return apiClient.put(baseUrl, params);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(id:any) {
  return apiClient.delete(`${baseUrl}?id=${id}`);
}