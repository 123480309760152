const hostUrl = `${window.location.origin}/api`;
//const hostUrl = `${process.env.REACT_APP_API_BASE}`;
export const apiClient = {
  get,
  post,
  put,
  delete: _delete,
  hostUrl: hostUrl,
};
function getHeaders() {
  return { "Content-Type": "application/json", APIKey: "gAwv9J5cwZ6gC0QEp04c" };
}
function get(url: any) {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };
  return fetch(url, requestOptions).then(handleResponse);
}

function post(url: any, body: any) {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(body),
  };
  return fetch(url, requestOptions).then(handleResponse);
}

function put(url: any, body: any) {
  const requestOptions = {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify(body),
  };
  return fetch(url, requestOptions).then(handleResponse);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(url: any) {
  const requestOptions = {
    method: "DELETE",
    headers: getHeaders(),
  };
  return fetch(url, requestOptions).then(handleResponse);
}

// helper functions
function getValidationError(responseException: any) {
  if (
    responseException.validationErrors &&
    responseException.validationErrors.length > 0
  ) {
    return responseException.validationErrors[0].message;
  }
  return;
}
function handleResponse(response: any) {
  return response.text().then((text: any) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error =
        (data &&
          `${data.responseException.exceptionMessage}\r\n ${getValidationError(
            data.responseException
          )}`) ||
        response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
