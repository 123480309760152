import { Table, Tag } from "antd";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { useState, useEffect } from "react";
import { loginRequest } from "../../config/authConfig";
import { Loading, ErrorComponent } from "../../components";
import { Category, categoryService } from "../../services";

const CategoryListContent = () => {
  const [categories, setCategories] = useState<Category[]>([]);

  useEffect(() => {
    categoryService.getAll().then((res) => {
      if (!res.isError) {
        setCategories(res.result);
      }
    });
  }, []);

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Description", dataIndex: "description", key: "description" },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (isActive: boolean) => {
        const color = isActive ? "green" : "volcano";
        return <Tag color={color}>{isActive ? "Active" : "Inactive"}</Tag>;
      },
    },
  ];

  const expandedRowRender = (record: Category) => {
    const data = categories
      .filter((category: Category) => category.parentId === record.id)
      .map((category: Category) => ({
        ...category,
        key: category.id,
      }));

    return <Table columns={columns} dataSource={data} pagination={false} />;
  };

  const data = categories
    .filter((category: Category) => category.parentId === null)
    .map((category: Category) => ({
      ...category,
      key: category.id || category.name,
    }));

  return (
    <Table
      className="components-table-demo-nested"
      columns={columns}
      expandable={{ expandedRowRender }}
      dataSource={data}
    />
  );
};

const CategoryList = ({ history, match }: any) => {
  const authRequest = {
    ...loginRequest,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Popup}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <CategoryListContent />
    </MsalAuthenticationTemplate>
  );
};

export { CategoryList };
