import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { Button, Collapse, Row, Avatar } from "antd";
import React from "react";
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  NavLink,
  useHistory,
} from "react-router-dom";

import { Layout, Menu } from "antd";
import {
  ApartmentOutlined,
  BankOutlined,
  QuestionCircleOutlined,
  DatabaseOutlined,
  UserOutlined,
  LogoutOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import DisplayAlert from "../../components/alert/alert";
import { TenantList, SubscriptionList, HelpList, CategoryList } from "../.";
import { SignUp } from "../user/signup";
import { loginRequest } from "../../config/authConfig";
const { Content, Sider } = Layout;
const { SubMenu } = Menu;
const rootSubmenuKeys = ["sub1", "sub2", "sub4"];

export function Home() {
  const { pathname } = useLocation();
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginPopup(loginRequest);
  };
  const handleLogout = () => {
    instance.logoutPopup({
      mainWindowRedirectUri: "/",
    });
  };
  const [openKeys, setOpenKeys] = React.useState(["sub1"]);

  const onOpenChange = (keys: any) => {
    const latestOpenKey = keys.find(
      (key: string) => openKeys.indexOf(key) === -1
    );
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <>
      <div className="app-container bg-light" data-testid="app-container-div">
        <Layout style={{ minHeight: "100vh" }}>
          <Sider collapsible>
            <Row
              style={{
                display: "flex",
                flexDirection: "column",
                minHeight: "100%",
              }}
            >
              <div style={{ flex: 1 }}>
                <Menu
                  theme="dark"
                  defaultSelectedKeys={["/"]}
                  selectedKeys={[pathname]}
                  mode="inline"
                >
                  {/* <Menu.Item key="1" icon={<UserOutlined />}>
                  <NavLink to="/sign-up">Sign Up</NavLink>
                </Menu.Item> */}
                  <Menu.Item key="/tenant" icon={<ApartmentOutlined />}>
                    <NavLink to="/tenant">Tenant</NavLink>
                  </Menu.Item>
                  <Menu.Item key="/subscription" icon={<BankOutlined />}>
                    <NavLink to="/subscription">Subscription</NavLink>
                  </Menu.Item>
                  <Menu.Item key="/category" icon={<DatabaseOutlined />}>
                    <NavLink to="/category">Category</NavLink>
                  </Menu.Item>
                  <Menu.Item key="/help" icon={<QuestionCircleOutlined />}>
                    <NavLink to="/help">Help</NavLink>
                  </Menu.Item>
                </Menu>
              </div>
              <div>
                <Menu
                  mode="inline"
                  openKeys={openKeys}
                  onOpenChange={onOpenChange}
                  theme="dark"
                >
                  <SubMenu
                    key="sub1"
                    icon={<Avatar icon={<UserOutlined />} />}
                    title="User"
                  >
                    {/* <Menu.Item
                      key="2"
                      onClick={() => handleLogin()}
                    >
                      Login
                    </Menu.Item> */}
                    <Menu.Item
                      key="3"
                      icon={<LogoutOutlined />}
                      onClick={() => handleLogout()}
                    >
                      Logout
                    </Menu.Item>
                  </SubMenu>
                </Menu>
              </div>
            </Row>
          </Sider>
          <Layout className="site-layout">
            <Content style={{ margin: "0 16px" }}>
              <div
                className="site-layout-background"
                style={{ padding: 24, minHeight: 360 }}
              >
                <DisplayAlert />
                <div className="container pt-4 pb-4">
                  <Switch>
                    <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
                    {/* <Route path="/sign-up" component={SignUp} /> */}
                    <Route exact path="/tenant" component={TenantList} />
                    <Route exact path="/subscription" component={SubscriptionList} />
                    <Route exact path="/category" component={CategoryList} />
                    <Route exact path="/help" component={HelpList} />
                    <Redirect from="*" to="/sign-up" />
                  </Switch>
                </div>
              </div>
            </Content>
          </Layout>
        </Layout>
      </div>
    </>
  );
}
