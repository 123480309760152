import { apiClient } from "../helpers";

const baseUrl = `${apiClient.hostUrl}/Payment`;

export const paymentService = {
    makePayment
};

function makePayment(data:any) {
    return  apiClient.post(`${baseUrl}/MakePayment`, data);
}

