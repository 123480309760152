import React, { useState, useEffect } from "react";
import { Form, Input, Select, Switch, Row, Col } from "antd";
import {
  Category,
  categoryService,
  Help,
  helpService,
} from "../../services";
import { HtmlEditor, EditableTagGroup } from "../../components";

interface Params {
  help?: Help;
  onSave: (values: Help) => void;
}

const HelpForm = ({ help, onSave }: Params) => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [subCategories, setSubCategories] = useState<Category[]>([]);
  const isEditing = !!help;

  useEffect(() => {
    categoryService.getAll().then((res) => {
      if (!res.isError) {
        setCategories(
          res.result.filter((category: Category) => category.parentId === null)
        );
      }
    });
    if (help?.categoryId) {
      categoryService.GetSubCategoryByParent(help.categoryId).then((res) => {
        if (!res.isError) {
          setSubCategories(res.result);
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  const onValuesChanges = ({ categoryId }: { categoryId: string }) => {
    if (categoryId) {
      categoryService.GetSubCategoryByParent(categoryId).then((res) => {
        setSubCategories(res.result);
      });
    }
  };

  const onFinish = async (values: any) => {
    if (isEditing) {
      values = {
        ...help,
        ...values,
      };
      await helpService.update(values);
    } else {
      await helpService.create(values);
    }

    onSave(values);
  };

  return (
    <Form
      id="help-form"
      layout="vertical"
      initialValues={help}
      onValuesChange={onValuesChanges}
      onFinish={onFinish}
    >
      <Row gutter={16}>
        <Col span={16}>
          <Form.Item
            label="Content"
            name="content"
            rules={[{ required: true, message: "Content is required!" }]}
          >
            {/* @ts-ignore */}
            <HtmlEditor style={{ height: 700 }} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: "Title is required!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: "Description is required!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Category"
            name="categoryId"
            rules={[{ required: true, message: "Category is required!" }]}
          >
            <Select>
              {categories.map((category, index) => (
                <Select.Option key={category.id || index} value={category.id}>
                  {category.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Sub-Category"
            name="subCategoryId"
            rules={[{ required: true, message: "Sub-Category is required!" }]}
          >
            <Select>
              {subCategories.map((subCategory, index) => (
                <Select.Option
                  key={subCategory.id || index}
                  value={subCategory.id}
                >
                  {subCategory.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="External Video URL"
            name="externalVideoURL"
            rules={[
              { required: true, message: "External Video URL is required!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Active" name="isActive" valuePropName="checked">
            <Switch />
          </Form.Item>
          <Form.Item
            label="Keywords"
            name="keywords"
            // rules={[{ required: true, message: "Keywords are required!" }]}
          >
            {/* @ts-ignore */}
            <EditableTagGroup />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export { HelpForm };
