import { useAccount, useMsal } from "@azure/msal-react";
import { Typography } from "antd";
import { ReactNode, useEffect, useState } from "react";
const { Title } = Typography;
type AdminRoleTemplateProps = { children: ReactNode };
type IdTokenClaims = {
  roles: string[];
};
const AdminRoleTemplate = ({ children }: AdminRoleTemplateProps) => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    if (account) {
      const idTokenClaims = account.idTokenClaims as IdTokenClaims;
      console.log(idTokenClaims.roles);
      const isAdminRole = idTokenClaims.roles?.includes("admin");
      setIsAdmin(isAdminRole);
    } else {
      setIsAdmin(false);
    }
  }, [account]);
  if (!isAdmin) {
    return <Title level={3}>Access Denied</Title>;
  } else {
    return <div>{children}</div>;
  }
};

export default AdminRoleTemplate;
