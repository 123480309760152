import React, {  useEffect } from 'react'
import {  Spin,  Card, Row, Col, Image, Typography, Space, Radio } from 'antd';
import payment from 'payment';
import { MasterCard, VisaCard, UnknownCard, AmexCard } from '../../../assets/svgs'
import { cardDetailService } from '../../../services';

const { Title, Text } = Typography;
const CreditCardActions = {
    FETCH_CARDDETAIL_REQUEST: "FETCH_CARDDETAIL_REQUEST",
    FETCH_CARDDETAIL_SUCCESS: "FETCH_CARDDETAIL_SUCCESS",
    FETCH_CARDDETAIL_FAILURE: "FETCH_CARDDETAIL_FAILURE"
}
const initialState = {
    cardDetails: [],
    isFetching: false,
    hasError: false,
};
const reducer = (state:any, action:any) => {
    switch (action.type) {
        case CreditCardActions.FETCH_CARDDETAIL_REQUEST:
            return {
                ...state,
                isFetching: true,
                hasError: false
            };
        case CreditCardActions.FETCH_CARDDETAIL_SUCCESS:
            return {
                ...state,
                isFetching: false,
                cardDetails: action.payload
            };
        case CreditCardActions.FETCH_CARDDETAIL_FAILURE:
            return {
                ...state,
                hasError: true,
                isFetching: false
            };
        default:
            return state;
    }
};

const Images:any = {
    visa: VisaCard,
    mastercard: MasterCard,
    amex: AmexCard,
    unknown: UnknownCard
}

const getCardImage = (number:any) => {
    let cardType = payment.fns.cardType(number)
    if (!cardType || !Images[cardType])
        cardType = 'unknown';
    return Images[cardType]
}

const SavedCreditCard = ({ cardDetail }:any) => {
    return (
        <Card style={{ padding: '2px' }}>
            <Radio value={cardDetail.id} style={{ alignItems: 'center' }}><Row gutter={16} align='middle'>
                <Col style={{ alignItems: 'center' }} span={18}>
                    <Title level={5}>
                        {new Array(cardDetail.cardNumber.length - 4 + 1).join('•') + cardDetail.cardNumber.slice(-4)}
                    </Title>
                    <Text type="secondary">Expires {cardDetail.expiryDate}</Text>
                </Col>
                <Col span={6}>
                    <Image preview={false} height={35} width={35} src={getCardImage(cardDetail.cardNumber)}></Image>
                </Col>
            </Row>
            </Radio>
        </Card>
    )
}

const SavedCreditCardList = ({ tenant, onSavedCardChanged, value ,selectedCardDetail={}}:any) => {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    useEffect(() => {

        dispatch({ type: CreditCardActions.FETCH_CARDDETAIL_REQUEST });
        cardDetailService.getCardDetailsByTenant(tenant.tenantId)
            .then(res => {
                if (res && res.result && res.result.length > 0) {
                    dispatch({
                        type: CreditCardActions.FETCH_CARDDETAIL_SUCCESS,
                        payload: res.result
                    });

                }

            })
            .catch(err => 
                dispatch({ type: CreditCardActions.FETCH_CARDDETAIL_FAILURE }))
    }, []);

    const onSelectionChanged = (e:any) =>{
        onSavedCardChanged(e, state.cardDetails)
    }
    return (<div>
        <Title level={5}>  My saved cards</Title>
        {!state.isFetching ? (state.cardDetails.length > 0 ? <Radio.Group onChange={onSelectionChanged} value={value}>
            <Space direction='vertical' >
                {state.cardDetails.map((item:any, i:any) => <SavedCreditCard key={i} cardDetail={item}></SavedCreditCard>)}
            </Space>
        </Radio.Group > :  <Space size="middle">No saved credit card</Space>) :
            (<Space size="middle">
                <Spin size="large" />
            </Space>)}
    </div>);
}

export default SavedCreditCardList;