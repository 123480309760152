import { Layout, Menu, Breadcrumb } from 'antd';
import {
    DesktopOutlined,
    PieChartOutlined,
} from '@ant-design/icons';
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
type PropTypes = {
    history: string,
    match: boolean
};
function MainLayout({ history, match }:PropTypes) {
    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider collapsible >
                <div className="logo" />
                <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
                    <Menu.Item key="1" icon={<PieChartOutlined />} title="Sign Up" >Sign Up</Menu.Item>
                    <Menu.Item key="2" icon={<DesktopOutlined />} title="Tenants" onClick={() => {
                        //props.history.push("/tenants");
                    }} >Tenants</Menu.Item>
                </Menu>
            </Sider>
            <Layout className="site-layout">
                <Content style={{ margin: '0 16px' }}>

                </Content>
            </Layout>
        </Layout>);
}

export { MainLayout };