import { apiClient } from "../helpers";

const baseUrl = `${apiClient.hostUrl}/Subscription`;

export const subscriptionService = {
  getAll,
  getSubscriptions,
};

function getAll() {
  return apiClient.get(baseUrl);
}

function getSubscriptions() {
  return apiClient.get(`${baseUrl}/GetSubscriptions`);
}
