import { Result, Button, Typography } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';

const { Paragraph, Text } = Typography;

const ErrorMessage = ({errorMessage}:any) => { return(<Result
    status="error"
    title="There are some problems with payment process."
    
    extra={
      <Button type="primary" key="console">
       Go To Home
      </Button>
    }
  >
      <div className="desc">
      <Paragraph>
        <Text
          strong
          style={{
            fontSize: 16,
          }}
        >
          The content you submitted has the following error:
        </Text>
      </Paragraph>
      <Paragraph>
        <CloseCircleOutlined style={{color: 'red'}} /> {errorMessage}
      </Paragraph>
     
    </div>
  </Result>)
}

const SuccessMessage = () => { 
  const history = useHistory()

    return( 
    <Result
        status="success"
        title="Payment Successfully !"
        subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
        extra={[
            <Button type="primary" key="console" onClick={()=>{
              history.push("/");
            }}>
                Go To Home
  </Button>,
        ]} />)
}
const PaymentSuccess = ({paymentError }:any) => {

    return (
        <>
       {paymentError.isError ? <ErrorMessage errorMessage={paymentError.error}></ErrorMessage> : <SuccessMessage></SuccessMessage>}
           </>
    );
}

export default PaymentSuccess

