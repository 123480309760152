import { apiClient } from "../helpers";

const baseUrl = `${apiClient.hostUrl}/Admin`;

export interface Tenant {
  id: string;
  key: string;
  firstName: string;
  lastName: string;
  name: string;
  emailAddress: string;
  country: string;
  company: string;
  companyURL: string;
  createdOn: string;
  modifiedOn: string;
  phoneNumber: string;
}

export const tenantService = {
  getAll,
  create,
  update
};

function getAll() {
  return apiClient.get(baseUrl);
}

function create(params: any) {
  return apiClient.post(baseUrl, params);
}

function update(params:any) {
  return apiClient.put(baseUrl, params);
}