import React, {  useEffect } from 'react';
import 'antd/dist/antd.css';
import { Form, Modal, Button, Steps,  Row } from 'antd';
import { LoadingOutlined, SmileOutlined } from '@ant-design/icons';
import OrderSummary from './ordersummary';
import PaymentMethod from './payment-method';
import CreditCardForm from './creditcard/creditcard-form';
import PaymentSuccess from './payment-success';
import { paymentService } from '../../services';
import { useHistory } from 'react-router';

const sleep = (ms:any) => new Promise(resolve => setTimeout(resolve, ms));
const { Step } = Steps;

const steps = [
    {
        title: 'Payment Methods',
        status: '',
        icon: null,
        content: <div></div>,
    },
    {
        title: 'Payment Detail',
        status: '',
        icon: null,
        content: <div></div>,
    },
    {
        title: 'Pay',
        status: '',
        icon: null,
        content: <div></div>,
    },
    {
        title: 'Done',
        status: '',
        icon: <SmileOutlined />,
        content: <div></div>,
    },
];

const PaymentError = {error:'',isError:false};
const PaymentRequest = { PaymentType: 0, TenantId: '', CreditCard: {} };
const ActionButtons = ({ next, prev, current, steps, onProceedToPay, isProceedToPayEnable }:any) => {
    return (<div className="steps-action">
        {current < steps.length - 1 && current < 2 && (
            <Button type="primary" onClick={() => next()}>
                Next
            </Button>
        )}
        {/* {current === steps.length - 1 && (
            <Button type="primary" onClick={() => message.success('Processing complete!')}>
                Done
            </Button>
        )} */}
        {current === 2 && (
            <Button type="primary" disabled={isProceedToPayEnable} onClick={() => onProceedToPay()}>
                Proceed to Pay
            </Button>
        )}
        {current > 0 && current < 2 && (
            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                Previous
            </Button>
        )}
    </div>)
}

export default function PaymentModel({ tenant, visible, setIsModalVisible, ...props }:any) {
    const [creditCardForm] = Form.useForm();
    const [stepList, setSteps] = React.useState<any>(steps);
    const [paymentError, setPaymentError] = React.useState(PaymentError);
    const [creditCardRequest, setCreditCardRequest] = React.useState<any>({});
    const [current, setCurrent] = React.useState(0);
    const [currentContent, setCurrentContent] = React.useState(null);
    const [isPaymentInProcess, setPaymentInProcess] = React.useState(false);
    const [paymentMethod, setPaymentMethod] = React.useState<any>({});
    const creditCardFormRef = React.createRef();
    const next = () => {
        if (current == 1) {
            creditCardForm.submit();
        }
        else {
            setCurrent(current + 1);
        }
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const onProceedToPay = async () => {
        setPaymentInProcess(true);
        stepList[current].icon = <LoadingOutlined />
        if (paymentMethod.type == "CreditCard") {
            const { roleWiseUsers, tenantId, totalAmount } = tenant;
            const LineItems = [];
            if (roleWiseUsers && roleWiseUsers.length > 0) {
                for (let index = 0; index < roleWiseUsers.length; index++) {
                    const element = roleWiseUsers[index];

                    LineItems.push({
                        ItemId: element.subscriptionId,
                        Name: element.role,
                        Quantity: element.userCount,
                        UnitPrice: element.rolePrice > 0 ? parseFloat(element.rolePrice.toFixed(2)) : 0.00
                    })
                }
            }
            creditCardRequest["Amount"] = totalAmount;
            creditCardRequest["LineItems"] = LineItems;
            PaymentRequest.PaymentType = 1;
            PaymentRequest.TenantId = tenantId;
            PaymentRequest.CreditCard = creditCardRequest;
        }
        await paymentService.makePayment(PaymentRequest).then(res => {
            const responsePayment = res.result;
            if(responsePayment && responsePayment.errorText == null)
            {
                PaymentError.isError=false;
                setPaymentError(paymentError);

            }
            else{
                PaymentError.error=responsePayment.errorText;
                PaymentError.isError=true;
                setPaymentError(PaymentError);
            }
        })
            .catch(err => {
                PaymentError.error=err.toString();
                PaymentError.isError=true;
                setPaymentError(PaymentError);
            });
        setPaymentInProcess(false);
        stepList[current].icon = null;
        next();
    }
    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        creditCardForm.resetFields();
        setCurrent(0);
        setIsModalVisible(false);
    };

    const onPaymentMethodChange = (method:any) => {
        setPaymentMethod(method);
    }
    const onFinish = (values:any) => {
        setCreditCardRequest(values);
        setCurrent(current + 1);
    };

    const onFinishFailed = (errorInfo:any) => {
    };

    useEffect(() => {
        if (current == 0) {
            stepList[current].content = <PaymentMethod tenant={tenant} onPaymentMethodChange={onPaymentMethodChange}></PaymentMethod>
        }
        else if (current == 1) {
                if (paymentMethod.type == "CreditCard") {
                stepList[current].content = <CreditCardForm tenant={tenant} form={creditCardForm} onSubmit={onFinish} onSubmitFailed={onFinishFailed} ref={creditCardFormRef} ></CreditCardForm>
            }
        }
        else if (current == 2) {
            stepList[current].content = <OrderSummary tenant={tenant}></OrderSummary>

        }
        else if (current == 3) {
            stepList[current].content = <PaymentSuccess paymentError={paymentError} ></PaymentSuccess>

        }
            setCurrentContent(stepList[current].content);

    }, [current]);

    return (
        <Modal
            width={1000}
            centered
            visible={visible}
            title="Payment"
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}> Return      </Button>,
            ]}
        >
             <React.Fragment>
            <Steps current={current} >
                {stepList.map((item:any,index:any) => (
                    <Step key={index} title={item.title} status={item.status} icon={item.icon} >
                        {currentContent}
                    </Step>
                ))}
            </Steps>
            </React.Fragment>
            <div className="steps-content">{stepList[current].content}</div>
            <Row justify="center">
                <ActionButtons next={next} prev={prev} steps={stepList} current={current} isProceedToPayEnable={isPaymentInProcess} onProceedToPay={onProceedToPay}> </ActionButtons>
            </Row>
        </Modal>
    );
}